.hero {
  height: calc(100vh - 80px);
  /* height: 100vh; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heroLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heroRight {
  flex: 1;
  display: flex;
  justify-content: center;
} 

.heroRight > img {
  width: 80%;
}

@media screen and (max-width: 1000px) {
  .hero {
    display: block;
    margin-top: 32px;
  }

  .heroLeft .ourProjectsBtn {
    margin-left: 0;
    margin-top: 16px;
  }
  .heroRight {
    margin-top: 16px;
  }
}