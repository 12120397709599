@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #EF233C;
  --primaryLight: rgba(239, 35, 60, 4%);
  --secondary: #3B82F6;
  --white: #FFFFFF;
  --bgDark: #2B2D42;
  --bgLight: #FAFAFA;
  --headingColor: #2B2D42;
  --bodyColor: #52525b;
  --bodyColorLight: rgba(60, 60, 60, 60%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

body {
  background-color: #fafafa !important;
}

.container {
  width: 80%;
  margin: 0px auto;
}

section {
  padding: 100px 0;
}

@media screen and (max-width: 1000px) {
  .container {
    width: 100%;
    padding: 0 24px;
  }
}


