.aboutSectionContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aboutSectionLeft {
  flex: 1;
}

.aboutSectionRight {
  flex: 1;
  display: flex;
  justify-content: center;
}

.aboutSectionRight > img {
  width: 60%;
}

.togtherWeList {
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}


@media screen and (max-width : 1000px) {
  .aboutSectionContent {
    display: block;
  }

  .togtherWeList {
    display: block;
  }

  .togetherWeCard {
    margin-top: 16px;
  }
}