.modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal__container {
  position:fixed;
  background: white;
  width: 40%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 24px;
  border-radius: 8px;
}

.modal__header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.modal__closeIcon {
  cursor: pointer;
}

@media screen and (max-width : 1000px) {
  .modal__container {
    position:fixed;
    background: white;
    height: 70% !important;
    width: 100%;
    /* height: auto; */
    bottom: 0%;
    transform: translate(-50%, 0%);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}